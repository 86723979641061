import { Container, Typography, Box, Paper } from '@mui/material';

function Other() {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 8, mb: 4 }}>
        <Typography variant="h1" gutterBottom>
          About Me
        </Typography>
        
        <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
          <Typography variant="body1" paragraph>
            Welcome to my personal page! Beyond my academic work, I'm passionate about
            [your interests/hobbies]. This section will be expanded to include more
            personal information and interests.
          </Typography>
          
          <Typography variant="body1" paragraph>
            Feel free to connect with me on:
          </Typography>
          
          <Box sx={{ ml: 2 }}>
            <Typography variant="body1">
              • Google Scholar: [Your Profile Link]
            </Typography>
            <Typography variant="body1">
              • LinkedIn: [Your Profile Link]
            </Typography>
            <Typography variant="body1">
              • Email: [Your Academic Email]
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default Other; 