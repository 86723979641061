import { Container, Typography, Box, Paper, Grid } from '@mui/material';

function Teaching() {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 8, mb: 4 }}>
        <Typography variant="h1" gutterBottom>
          Teaching
        </Typography>

        <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Current Teaching
          </Typography>
          <Box sx={{ ml: 2 }}>
            <Typography variant="body1" paragraph>
              <strong>Course Name</strong>
              <br />
              Teaching Assistant
              <br />
              Spring 2024
              <br />
              [University Name]
            </Typography>
          </Box>
        </Paper>

        <Paper elevation={1} sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Previous Teaching Experience
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ ml: 2 }}>
                <Typography variant="body1" paragraph>
                  <strong>Course Name</strong>
                  <br />
                  Teaching Assistant
                  <br />
                  Fall 2023
                  <br />
                  [University Name]
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}

export default Teaching; 