import { Container, Typography, Box, Paper } from '@mui/material';

function Research() {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 8, mb: 4 }}>
        <Typography variant="h1" gutterBottom>
          Research
        </Typography>
        
        <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Working Papers
          </Typography>
          <Box sx={{ ml: 2 }}>
            <Typography variant="body1" paragraph>
              "Paper Title 1" (Job Market Paper)
              <br />
              <em>Status: In progress</em>
            </Typography>
            
            <Typography variant="body1" paragraph>
              "Paper Title 2"
              <br />
              <em>Status: Under review</em>
            </Typography>
          </Box>
        </Paper>

        <Paper elevation={1} sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Work in Progress
          </Typography>
          <Box sx={{ ml: 2 }}>
            <Typography variant="body1">
              "Project Title 1"
              <br />
              <em>With [Coauthor Name]</em>
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default Research; 